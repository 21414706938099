import React from "react"
import config from "../../config"

const ConnectToTiktok:React.FC = () => {
    return (
        <a href={config.TIKTOK_OAUTH} rel="noopener" >
            Connect to Tiktok
        </a>
    )
}

export {ConnectToTiktok}

